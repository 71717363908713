import React from "react"
import { Link } from "gatsby"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    zIndex: "999",
    width: "100% !important",
    padding: "40px 20px 20px 20px",
    display: "grid",
    backgroundColor: "#9736A5",
    "& h1, p, a, button": {
      textTransform: "capitalize !important",
      fontSize: "1rem",
      fontWeight: "550",
      color: "#fff",
    },
    placeItems: "center",
  },
  container: {
    width: "80%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  navbar: {
    "& a": {
      color: "#fff !important",
      textDecoration: "none",
    },
  },
  navItem: {
    display: "grid",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  navLink: {
    padding: "20px 20px",
  },
  socials: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  logo: {
    maxWidth: "100%",
    height: "60px !important",
    padding: "0",
    margin: "0",
  },
}))

const data = {
  navbar: [
    {
      label: "about",
      link: "",
    },
    {
      label: "help",
      link: "",
    },
    {
      label: "gold",
      link: "",
    },
    {
      label: "blog",
      link: "",
    },
    {
      label: "careers",
      link: "",
    },
  ],
  socials: [
    { id: 0, label: "facebook", link: "/", icon: <FacebookIcon /> },
    { id: 1, label: "instagram", link: "/", icon: <InstagramIcon /> },
    { id: 3, label: "twitter", link: "/", icon: <TwitterIcon /> },
  ],
}

const Footer = () => {
  const classes = useStyles()
  var d = new Date()
  var n = d.getFullYear()
  return (
    <footer className={classes.root}>
      <Grid
        container
        direction={"row"}
        className={classes.container}
        justify="between"
        alignItems="center"
      >
        <Grid item container md={12}>
          <Grid
            container
            item
            md={12}
            lg={12}
            sm={12}
            direction={"row"}
            className={classes.socials}
            justify="center"
            alignItems="center"
          >
            {data.socials.map(item => (
              <Grid item key={item.id}>
                <Link to="/" className={classes.navLink}>
                  {item.icon}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          className={classes.container}
          justify="center"
          alignItems="center"
        >
          <Typography component="p" className="white">
            &copy; RajaaRani.com All Rights Reserved {n}
          </Typography>
          <Link to="/privacy-policy" className={classes.navLink}>
            Privacy Policy
          </Link>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer
