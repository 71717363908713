import React from "react"
import {
  TextField,
  Grid,
  Typography,
  Button,
  withStyles,
} from "@material-ui/core"

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField)

const WaitListForm = () => {
  return (
    <div className="waitListForm">
      <Grid container item md={8} sm={12} className="text-center">
        <Grid item md={12} xs={12}>
          <Typography component="h1" className="text-center gold heading">
            Join the Waitlist today!
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className="paragraph text-center white">
            Interested in Raja Rani?
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <CssTextField
            type="text"
            name="name"
            label="Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CssTextField
            type="email"
            name="email"
            label="Email"
            color="primary"
            fullWidth
            required
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CssTextField
            type="text"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CssTextField type="City" name="city" label="City" fullWidth />
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
            style={{
              backgroundColor: "#E9A939",
              color: "#fff",
              padding: "16px 36px",
              marginTop: "20px",
              borderRadius: "30px",
              textTransform: "none",
            }}
          >
            Join WaitList
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
export default WaitListForm
