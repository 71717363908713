import React from "react";
import WaitListForm from "./form";
import bgImage from "../images/footerbg.png"
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme)=>({
    root:{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',    
    }
}))

const WaitList = () => {
    const classes = useStyles();
    return (
        <div className="container" id="waitlist">
            <div className={clsx("WaitList-section", classes.root)}>
                <div className="wrapper">
                    <div className="flex">
                    <WaitListForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaitList;