import React from "react"
import PropTypes from "prop-types"
import Header from "./header.jsx"
import Footer from "./footer.jsx"
import "./layout.css"
import WaitList from "../components/waitlist.jsx"


const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <WaitList/>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
