import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Button, Grid, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Logo from "../images/header_logo.png"

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    zIndex: "999",
    width: "100% !important",
    padding:"20px",
    display: 'grid', 
    '& h1, p, a, button':{
      textTransform: 'capitalize !important',
      fontSize: '1rem',
      fontWeight: '550',
      color: '#fff'
    }
    
  },
  container:{
    maxHeight: '80px !important',
    height:'80px',
    position: 'relative',
    overflow: 'hidden',
    
  },
  navbar: {
    padding: "0 10%",
    color: "#fff !important",
    '& a' :{
      color: "#fff !important",
      textDecoration: 'none'
    }
  },
  logo: {
    maxWidth:'100%',
    height:'60px !important',
    padding: '0',
    margin: '0',
    animation: '$beat .25s 4 alternate',
    transformOrigin: 'center',
    '& :hover':{
      animation: '$beat .25s 4 alternate',
        
  }
    
  },
  signUp:{
    textAlign:"right",

  },
  '@keyframes beat':{
    "to": { transform: 'scale(1.4)' }
  }
}))

const Header = () => {
  const classes = useStyles()
  return (
    <header className={classes.root}>
      <Grid container direction={"row"} className={classes.container}  justify="space-between"
  alignItems="center">
        <Grid item sm={6} xs={6} md={2}>
          <Link to="/">
            <img src={Logo} className={classes.logo} alt="rajaarani logo" />
          </Link>
        </Grid>
        <Hidden mdDown>
        <Grid container item  md={8}>
          
        </Grid>
        </Hidden>
        <Grid container item xs={6}sm={6} md={2}className={classes.signUp} justify="flex-end">
        <Link to="#waitlist"
          style={{width:"inherit"}}
          >
          <Button
          style={{
            backgroundColor: "#E9A939",
            color: "#fff",
            padding: "12px 26px",
            borderRadius: "30px",
            textTransform: "none",
          }}
        >
          
          Join Waitlist
         
        </Button>   
        </Link>      
        </Grid>
        
      </Grid>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
